import { Link } from "react-router-dom";
import WebLayout from "../../layout/WebLayout";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import AttendanceAction from "../../stores/action/attendanceAction";
import moment from "moment";
import Breadcrumb from "../../components/Breadcrumb";
import TableFilter from "../../components/TableFilter";
import ImageComponent from "../../components/ImageComponent";

function ViewAttendance() {
  const [search, set_search] = useState<any>("");
  const [limit, set_limit] = useState<any>(10);
  const [page, set_page] = useState<any>(1);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Set the first and last date of the current month by default
  useEffect(() => {
    // Get current month
    const currentMonth = moment().month();
    const currentYear = moment().year();

    // Calculate the first date of the current month (1st day)
    const firstDateOfMonth = moment([currentYear, currentMonth, 1]).format(
      "YYYY-MM-DD",
    );

    // Calculate the last date of the current month
    const lastDateOfMonth = moment(firstDateOfMonth)
      .endOf("month")
      .format("YYYY-MM-DD");

    // Set the start and end dates
    setStartDate(firstDateOfMonth);
    setEndDate(lastDateOfMonth);
  }, []);

  // Handle start date change
  const handleStartDateChange = (e: any) => {
    setStartDate(e.target.value);
  };

  // Handle end date change
  const handleEndDateChange = (e: any) => {
    setEndDate(e.target.value);
  };

  const dispatch = useDispatch<any>();
  const { viewAttendanceList, currentPage, lastPage, totalData, from, to } =
    useSelector((state: RootState) => state.attendance);

  const maxPagesToShow = 5;
  const pagination = [];

  const startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getViewAttendance = useCallback(async () => {
    await dispatch(
      AttendanceAction.viewAttendance({
        limit: limit,
        search: search,
        page: page,
        startDate: startDate,
        endDate: endDate,
      }),
    );
  }, [dispatch, limit, search, page, startDate, endDate]);

  useEffect(() => {
    getViewAttendance();
  }, [getViewAttendance, search, limit, page, startDate, endDate]);

  return (
    <WebLayout pageName={"ViewAttendance"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <Breadcrumb mainTitle={"Attendance"} subTitle={"View Attendance"} />
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="header">
                  <h2>Attendance Summary</h2>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <label>From Date:</label>
                      <input
                        type="date"
                        value={startDate}
                        onChange={handleStartDateChange}
                        placeholder="Select start date"
                        style={{
                          backgroundColor: "white",
                          border: "1px solid",
                          marginTop: "2 rem",
                          padding: "0 10px",
                          width: "90%",
                        }}
                      />
                    </div>
                    <p className={"text-black"} style={{ marginTop: 25 }}>
                      <strong>&emsp;to&emsp;</strong>
                    </p>
                    <div>
                      <label>To Date:</label>
                      <input
                        type="date"
                        value={endDate}
                        onChange={handleEndDateChange}
                        placeholder="Select end date"
                        style={{
                          backgroundColor: "white",
                          border: "1px solid",
                          padding: "0 10px",
                          width: "90%",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <TableFilter
                  limit={limit}
                  set_limit={set_limit}
                  search={search}
                  set_search={set_search}
                />
                <div className="body">
                  <div className="table-responsive">
                    <table
                      id="basicTable"
                      className="table table-hover table-checkable order-column contact_list"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th> Employee Id</th>
                          <th> Image</th>
                          <th> Name</th>
                          <th> Last Absent</th>
                          <th> Leaves</th>
                          <th> Account Status</th>
                          <th> Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {viewAttendanceList?.length > 0 ? (
                          viewAttendanceList.map(
                            (dataList: any, index: number) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    {dataList.employeeId
                                      ? dataList.employeeId
                                      : "N/A"}{" "}
                                    ({dataList.mobile})
                                  </td>
                                  <td>
                                    <ImageComponent
                                      sourceImage={dataList.profile_img}
                                      width={80}
                                      height={80}
                                      altTitle={"Profile Image"}
                                    />
                                  </td>
                                  <td>{dataList.name}</td>
                                  <td>2 days ago</td>
                                  <td>
                                    {dataList?.attendance.map(
                                      (item: any, index: number) => {
                                        return (
                                          <table key={index}>
                                            {Object.entries(item).map(
                                              ([key, val]) => (
                                                <tr>
                                                  <th
                                                    style={{
                                                      width: "50%",
                                                      backgroundColor:
                                                        "transparent",
                                                      border: "unset",
                                                      padding: 0,
                                                    }}
                                                  >
                                                    {key}
                                                  </th>
                                                  <th
                                                    style={{
                                                      width: "50%",
                                                      backgroundColor:
                                                        "transparent",
                                                      border: "unset",
                                                      padding: 0,
                                                    }}
                                                  >
                                                    {typeof val === "string" ||
                                                    typeof val === "number"
                                                      ? val
                                                      : JSON.stringify(val)}
                                                  </th>
                                                </tr>
                                              ),
                                            )}
                                          </table>
                                        );
                                      },
                                    )}
                                  </td>
                                  <td>
                                    <label
                                      className={`label label-sm ${dataList.employee_status === "PENDING" ? "label-warning" : dataList.employee_status === "ACTIVE" ? "label-info" : "label-danger"}`}
                                      style={{ borderRadius: "0 !important" }}
                                    >
                                      {dataList.employee_status}
                                    </label>
                                  </td>
                                  <td>
                                    <Link
                                      to={`../employee-attendance/${dataList.id}`}
                                    >
                                      <button
                                        className={"btn btn-sm btn-success"}
                                        style={{ borderRadius: "0 !important" }}
                                      >
                                        View
                                      </button>
                                    </Link>
                                  </td>
                                </tr>
                              );
                            },
                          )
                        ) : (
                          <tr>
                            <td colSpan={10}>No matching records found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {viewAttendanceList?.length > 0 ? (
                    <div className="row mt-2 justify-content-between">
                      <div className="col-md-auto me-auto ">
                        <div className="dt-info">
                          Showing {from} to {to} of {totalData} entries
                        </div>
                      </div>
                      <div className="col-md-auto ms-auto ">
                        <div className="dt-paging paging_full_numbers">
                          <ul className="pagination">
                            <li
                              className="dt-paging-button page-item disabled"
                              onClick={() => set_page(1)}
                            >
                              <a
                                className="page-link first"
                                aria-controls="tableGroup"
                                aria-disabled="true"
                                aria-label="First"
                                data-dt-idx="first"
                              >
                                <span aria-hidden="true">&laquo;</span>
                              </a>
                            </li>
                            <li
                              className={`dt-paging-button page-item prev ${
                                page === 1 ? "disabled" : ""
                              }`}
                              onClick={() =>
                                page === 1 ? "" : set_page(currentPage - 1)
                              }
                            >
                              <a
                                className="page-link previous"
                                aria-controls="tableGroup"
                                aria-disabled="true"
                                aria-label="Previous"
                                data-dt-idx="previous"
                              >
                                <span aria-hidden="true">&lt;</span>
                              </a>
                            </li>
                            {pagination.map((p: any, index: number) => {
                              return (
                                <li
                                  key={index}
                                  className={`dt-paging-button page-item ${
                                    currentPage === p ? "active" : ""
                                  }`}
                                  onClick={() => set_page(p)}
                                >
                                  <a
                                    className="page-link"
                                    aria-controls="tableGroup"
                                    aria-current="page"
                                    data-dt-idx="0"
                                  >
                                    {p}
                                  </a>
                                </li>
                              );
                            })}

                            <li
                              className={`dt-paging-button page-item next ${
                                lastPage === page ? "disabled" : ""
                              }`}
                              onClick={() =>
                                lastPage === page
                                  ? ""
                                  : set_page(currentPage + 1)
                              }
                            >
                              <a
                                className="page-link next"
                                aria-controls="tableGroup"
                                aria-label="Next"
                                data-dt-idx="next"
                              >
                                <span aria-hidden="true">&gt;</span>
                              </a>
                            </li>
                            <li
                              className="dt-paging-button page-item"
                              onClick={() => set_page(lastPage)}
                            >
                              <a
                                className="page-link last"
                                aria-controls="tableGroup"
                                aria-label="Last"
                                data-dt-idx="last"
                              >
                                <span aria-hidden="true">&raquo;</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default ViewAttendance;
