import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { EMPLOYER_TOKEN } from "../../utils/Constants";
import AuthAction from "../../stores/action/authAction";
import toast from "react-hot-toast";

type FormData = {
  username: string;
  password: string;
};

function Login() {
  const { register, handleSubmit, formState } = useForm<FormData>();
  const dispatch = useDispatch()<any>;
  const { authenticated, token } = useSelector(
    (state: RootState) => state.auth,
  );
  const page = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const tokens = localStorage.getItem(EMPLOYER_TOKEN);
    if ((authenticated && token) || tokens) {
      page("/dashboard");
      toast.success("Welcome to ASL Employer Panel !!");
    }
  }, [authenticated, page, token]);

  const { isSubmitting } = formState;
  const onSubmit = handleSubmit(async (data) => {
    await dispatch(
      AuthAction.logIn({
        username: data.username,
        password: data.password,
      }),
    );
  });

  return (
    <React.Fragment>
      <link href={"./assets/css/style.css"} rel="stylesheet" />
      <link href={"./assets/css/pages/extra_pages.css"} rel="stylesheet" />
      <div className="container-login100">
        <div className="login-left-side">
          <img
            src={"./assets/images/login_bg.jpg"}
            alt="Login Background"
            className="login-image"
          />
        </div>
        <div className="login-right-side">
          <div className="login-form-container">
            <form
              className="login100-form validate-form text-center"
              onSubmit={onSubmit}
            >
              <img src={"./assets/images/asl_logo.png"} alt="ASL Logo" />
              <h1 className={"form-title"}>Sign In</h1>
              <div className="wrap-input100 validate-input">
                <input
                  className="input100 styled-input"
                  type="text"
                  {...register("username")}
                  placeholder="Username"
                  required={true}
                />
              </div>
              <div className="wrap-input100 validate-input">
                <input
                  className="input100 styled-input"
                  type={showPassword ? "text" : "password"}
                  {...register("password")}
                  placeholder="Password"
                  required={true}
                />
                <span
                  onClick={togglePasswordVisibility}
                  className="password-toggle"
                >
                  {showPassword ? (
                    <i className="fa fa-eye-slash" />
                  ) : (
                    <i className="fa fa-eye" />
                  )}
                </span>
              </div>
              <div className="container-login100-form-btn">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="button-50"
                  role="button"
                >
                  {isSubmitting && (
                    <span className="fa fa-spinner fa-spin mr-1"></span>
                  )}
                  &nbsp;LOGIN
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Login;
