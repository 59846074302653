const StaffDutyType = {
  CREATE_STAFF_DUTY: "CREATE_STAFF_DUTY",
  GET_STAFF_DUTY: "GET_STAFF_DUTY",
  REMOVE_STAFF_DUTY: "REMOVE_STAFF_DUTY",
  UPDATE_STAFF_DUTY: "UPDATE_STAFF_DUTY",
  STAFF_RECORD_DUTY: "STAFF_RECORD_DUTY",
};

export interface CreateStaffDuty {
  name: string;
  startTime: string;
  endTime: string;
}

export interface GetStaffDuty {
  search: string;
  limit: number;
  page: number;
}

export interface UpdateStaffDuty {
  name: string;
  startTime: string;
  endTime: string;
  id: number;
}

export interface RemoveStaffDuty {
  id: number;
}

export default StaffDutyType;
