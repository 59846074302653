import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import StaffDutyAction from "../stores/action/staffDutyAction";

type FormData = {
  name: string;
  startTime: string;
  endTime: string;
};

function AddStaffDutyModal({ modal, setModal, getStaffDutyList }: any) {
  const dispatch = useDispatch<any>();

  // Loading state
  const [loading, setLoading] = useState<boolean>(false);

  const methods = useForm<FormData>({
    defaultValues: {
      name: "",
      startTime: "",
      endTime: "",
    },
  });

  const {
    register,
    reset,
    formState: { errors },
  } = methods;

  // Submit Handlers
  const onSubmit = async (data: FormData) => {
    try {
      setLoading(true);
      await dispatch(
        StaffDutyAction.createStaffDuty({
          name: data.name,
          startTime: data.startTime,
          endTime: data.endTime,
        }),
      );
      setLoading(false);
      setModal(false);
      getStaffDutyList();
      reset();
    } catch (error) {
      console.error("Error updating staff duty:", error);
      setLoading(false);
    }
  };

  // Close Steps
  const closeModal = () => {
    setModal(false);
  };

  return (
    <div className="modal" style={{ display: modal ? "block" : "none" }}>
      <div className="modal-dialog" role="document">
        <form
          className="needs-validation"
          onSubmit={methods.handleSubmit(onSubmit)}
          encType="multipart/form-data"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-dark">Add Staff Duty</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModal}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label>
                      Name
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      {...register("name", { required: true })}
                    />
                    {errors.name && (
                      <span className="text-danger">
                        Staff Duty Name is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label>
                      Start Time
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      {...register("startTime", { required: true })}
                    />
                    {errors.startTime && (
                      <span className="text-danger">
                        Start time is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label>
                      End Time
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="time"
                      className="form-control"
                      {...register("endTime", { required: true })}
                    />
                    {errors.endTime && (
                      <span className="text-danger">End time is required</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger light"
                disabled={loading}
                onClick={closeModal}
              >
                Close
              </button>
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary px-4"
              >
                {loading ? <i className="fa fa-spinner fa-spin"></i> : "Save"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddStaffDutyModal;
