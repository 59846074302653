import React, { useCallback, useEffect, useState } from "react";
import Step1Form from "./Steps/kyc/Step1Form";
import Step2Form from "./Steps/kyc/Step2Form";
import Step3Form from "./Steps/kyc/Step3Form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../stores";
import EmployerAction from "../stores/action/employerAction";
import WebAction from "../stores/action/webAction";

const StepModal = () => {
  const dispatch = useDispatch<any>();
  const { employerDetails } = useSelector((state: RootState) => state.employer);

  const [currentStep, setCurrentStep] = useState(1);
  const [showModal, setShowModal] = useState(true);
  const [message, setMessage] = useState("");

  const getEmployerData = useCallback(async () => {
    await dispatch(EmployerAction.getEmployerDetails());
    await dispatch(WebAction.getState());
    await dispatch(WebAction.getCompanyType());
  }, [dispatch]);

  useEffect(() => {
    getEmployerData();
  }, [getEmployerData]);

  const handleNextStep = () => {
    setMessage(
      currentStep === 1
        ? "Congratulations! 🎊 You’ve successfully completed your first milestone 🎯 <br/> Now, take the next step by completing your KYC documents 📝."
        : currentStep === 2
          ? "Congratulations! 🎊 You’ve successfully completed your second milestone 🎯 <br/> Now, take the next step by completing your Bank details 📝."
          : currentStep === 3
            ? "Congratulations! 🎊 You’ve successfully completed all your milestones 🎯"
            : "",
    );

    setTimeout(() => {
      setMessage("");
      setCurrentStep((prevStep) => prevStep + 1);
      if (currentStep === 3) {
        setShowModal(false);
      }
    }, 2000);
  };

  const handlePreviousStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <Step1Form
            onNext={handleNextStep}
            onPrevious={handlePreviousStep}
            editableData={employerDetails}
            getEmployerData={getEmployerData}
          />
        );
      case 2:
        return (
          <Step2Form
            onNext={handleNextStep}
            onPrevious={handlePreviousStep}
            editableData={employerDetails}
            getEmployerData={getEmployerData}
          />
        );
      case 3:
        return (
          <Step3Form
            onNext={handleNextStep}
            onPrevious={handlePreviousStep}
            editableData={employerDetails}
            getEmployerData={getEmployerData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {showModal && (
        <>
          {/* Overlay */}
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9998,
            }}
          />

          {/* Modal */}
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              padding: "20px",
              zIndex: 9999,
            }}
          >
            {message && (
              <div
                className={`p-2 ${currentStep === 1 ? "bg-dark-orange" : currentStep === 2 ? "bg-dark-green" : "bg-dark-info"}`}
              >
                <h4
                  className={"m-0"}
                  // style={{ color: "whitesmoke" }}
                  dangerouslySetInnerHTML={{ __html: message }}
                />
              </div>
            )}

            {renderStep()}
            <button onClick={() => setShowModal(false)}>Close</button>
          </div>
        </>
      )}
    </>
  );
};

export default StepModal;
