import { Link } from "react-router-dom";

function Header({ logoutHandle }: any) {
  return (
    <>
      <nav className="navbar">
        <div className="container-fluid">
          <div className="navbar-header">
            <Link
              to="#"
              className="navbar-toggle collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#navbar-collapse"
              aria-expanded="false"
            ></Link>
            <Link to="#" className="bars"></Link>
            <Link className="navbar-brand" to="/dashboard">
              <img
                className="logo-name manage_logo"
                src={"./assets/images/asl_logo.png"}
                alt={"Logo"}
              />
            </Link>
          </div>
          <div className="collapse navbar-collapse" id="navbar-collapse">
            <ul className="pull-left">
              <li>
                <Link to="#" className="sidemenu-collapse">
                  <i className="fa fa-bars"></i>
                </Link>
              </li>
            </ul>
            <ul className="nav navbar-nav navbar-right">
              <li className="dropdown user_profile">
                <div className="dropdown-toggle" data-bs-toggle="dropdown">
                  <img
                    src={"./assets/images/asl_logo.jpeg"}
                    alt="Logo"
                    width={30}
                  />
                </div>
                <ul className="dropdown-menu pullDown">
                  <li className="body">
                    <ul className="user_dw_menu">
                      <li>
                        <Link to="../profile">
                          <i className="material-icons" />
                          Profile
                        </Link>
                      </li>
                      <li>
                        <Link to="#" onClick={() => logoutHandle()}>
                          <i className="material-icons" />
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
