import { Route, Routes } from "react-router-dom";
import Home from "../views/dashboard/Home";
import Login from "../views/auth/Login";
import Profile from "../views/employer/Profile";
import Error from "../views/Error";
import EmployeeList from "../views/employee/EmployeeList";
import EmployeeProfile from "../views/employee/EmployeeProfile";
import CreateEmployee from "../views/employee/CreateEmployee";
import Department from "../views/department/Department";
import Designation from "../views/designation/Designation";
import Attendance from "../views/attendance/Attendance";
import LeaveType from "../views/leave/LeaveType";
import Holiday from "../views/holiday/Holiday";
import ViewAttendance from "../views/attendance/ViewAttendance";
import EmployeeAttendance from "../views/attendance/EmployeeAttendance";
import LeaveRequestList from "../views/leave/LeaveRequestList";
import SalaryGenerateList from "../views/salary/SalaryGenerateList";
import SalaryStructure from "../views/salary/SalaryStructure";
import StaffDuty from "../views/staff/StaffDuty";
import AssignStaffDuty from "../views/staff/AssignStaffDuty";

function PageRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dashboard" element={<Home />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/create-employee" element={<CreateEmployee />} />
      <Route path="/employee-list" element={<EmployeeList />} />
      <Route path="/employee-profile/:id" element={<EmployeeProfile />} />
      <Route path="/department" element={<Department />} />
      <Route path="/designation" element={<Designation />} />
      <Route path="/mark-attendance" element={<Attendance />} />
      <Route path="/leave" element={<LeaveType />} />
      <Route path="/holiday" element={<Holiday />} />
      <Route path="/view-attendance" element={<ViewAttendance />} />
      <Route path="/employee-attendance/:id" element={<EmployeeAttendance />} />
      <Route path="/leave-applications" element={<LeaveRequestList />} />
      <Route path="/salary-generate" element={<SalaryGenerateList />} />
      <Route path="/salary-structure" element={<SalaryStructure />} />
      <Route path="/staff-schedule" element={<StaffDuty />} />
      <Route path="/assign-staff-duty" element={<AssignStaffDuty />} />
      <Route path="*" element={<Error />} />
    </Routes>
  );
}

export default PageRoutes;
