import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import SalaryAction from "../stores/action/salaryAction";
import { Link } from "react-router-dom";

function PaymentModal({
  modal,
  setModal,
  getSalaryRequest,
  selectedRows,
  setSelectedRows,
  setSelectAll,
  totalAmount,
  totalGstAmount,
  totalServiceFee,
  totalAmountReceivable,
}: any) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch<any>();

  // Loading state
  const [loading, setLoading] = useState<boolean>(false);

  // Profile Details State
  const [paymentSlip, setPaymentSlip] = useState<File | string>("");
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const handlePaymentImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setPaymentSlip(file);
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);

      // Optional: Clean up the URL object when the component unmounts or the file changes
      return () => {
        URL.revokeObjectURL(url);
      };
    }
  };

  // Submit Handlers
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      await dispatch(
        SalaryAction.salaryPaymentSlip({
          paymentSlip: paymentSlip,
          employeeId: selectedRows,
          totalAmount,
          totalGstAmount,
          totalServiceFee,
          totalAmountReceivable,
        }),
      );
      setLoading(false);
      setModal(false);
      getSalaryRequest();
      setSelectedRows([]);
      setPaymentSlip("");
      setPreviewUrl("");
      setSelectAll("");
    } catch (error) {
      console.error("Error updating payment:", error);
      setLoading(false);
      // Reset the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  // Close Steps
  const closeModal = () => {
    setModal(false);
  };

  return (
    <div className="modal" style={{ display: modal ? "block" : "none" }}>
      <div className="modal-dialog" role="document">
        <form
          className="needs-validation"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-dark">Payment Slip</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModal}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <label>Upload Payment Slip</label>
                  <div className="form-group">
                    <input
                      type="file"
                      className="form-control"
                      onChange={handlePaymentImageChange}
                      accept="image/*"
                      required={true}
                      ref={fileInputRef}
                    />
                  </div>
                  {previewUrl ? (
                    <Link to={`${previewUrl}`} target={"_blank"}>
                      <img src={`${previewUrl}`} width={200} height={200} />
                    </Link>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger light"
                disabled={loading}
                onClick={closeModal}
              >
                Close
              </button>
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary px-4"
              >
                {loading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : (
                  "PROCEED"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PaymentModal;
