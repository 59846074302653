import React, { useState } from "react";
import { useDispatch } from "react-redux";
import HolidayAction from "../stores/action/holidayAction";
import { useForm } from "react-hook-form";

type FormData = {
  name: string;
  date: string;
};

function AddHolidayModal({ modal, setModal, getHolidayList }: any) {
  const dispatch = useDispatch<any>();

  // Loading state
  const [loading, setLoading] = useState<boolean>(false);

  const methods = useForm<FormData>({
    defaultValues: {
      name: "",
    },
  });

  const {
    register,
    reset,
    formState: { errors },
  } = methods;

  // Submit Handlers
  const onSubmit = async (data: FormData) => {
    try {
      setLoading(true);
      await dispatch(
        HolidayAction.createHoliday({
          name: data.name,
          date: data.date,
        }),
      );
      setLoading(false);
      setModal(false);
      getHolidayList();
      reset();
    } catch (error) {
      console.error("Error updating holiday:", error);
      setLoading(false);
    }
  };

  // Close Steps
  const closeModal = () => {
    setModal(false);
  };

  return (
    <div className="modal" style={{ display: modal ? "block" : "none" }}>
      <div className="modal-dialog" role="document">
        <form
          className="needs-validation"
          onSubmit={methods.handleSubmit(onSubmit)}
          encType="multipart/form-data"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-dark">Add Holiday</h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModal}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label>
                      Holiday Name
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Holiday Name"
                      {...register("name", { required: true })}
                    />
                    {errors.name && (
                      <span className="text-danger">
                        Holiday Name is required
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label>
                      Date
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      {...register("date", { required: true })}
                    />
                    {errors.date && (
                      <span className="text-danger">Date is required</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger light"
                disabled={loading}
                onClick={closeModal}
              >
                Close
              </button>
              <button
                type="submit"
                disabled={loading}
                className="btn btn-primary px-4"
              >
                {loading ? <i className="fa fa-spinner fa-spin"></i> : "Save"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddHolidayModal;
