import React, { useCallback, useEffect, useState } from "react";
import WebLayout from "../../layout/WebLayout";
import { useDispatch, useSelector } from "react-redux";
import EmployerAction from "../../stores/action/employerAction";
import { RootState } from "../../stores";
import BasicDetails from "../../components/Profile/BasicDetails";
import WebAction from "../../stores/action/webAction";
import BankDetails from "../../components/Profile/BankDetails";
import ChangePassword from "../../components/Profile/ChangePassword";
import EditKYCDetails from "../../components/Profile/EditKYCDetails";
import ViewKYCDetails from "../../components/Profile/ViewKYCDetails";
import ImageComponent from "../../components/ImageComponent";
import Breadcrumb from "../../components/Breadcrumb";

function Profile() {
  const dispatch = useDispatch<any>();
  const { employerDetails } = useSelector((state: RootState) => state.employer);

  // State to keep track of the active tab
  const [activeTab, setActiveTab] = useState<string>("basicDetails");

  // Fetch employer data
  const getEmployerData = useCallback(async () => {
    await dispatch(EmployerAction.getEmployerDetails());
    await dispatch(WebAction.getState());
    await dispatch(WebAction.getCompanyType());
  }, [dispatch]);

  // Set the active tab based on the URL hash
  useEffect(() => {
    const currentHash = window.location.hash.substring(1);
    if (currentHash) {
      setActiveTab(currentHash);
    } else {
      setActiveTab("basicDetails");
    }
  }, []);

  // Update the URL hash when tab is changed
  const handleTabClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
    tabName: string,
  ) => {
    event.preventDefault(); // Prevent default anchor click behavior
    setActiveTab(tabName);
    window.location.hash = tabName;
  };

  useEffect(() => {
    getEmployerData();
  }, [getEmployerData]);

  return (
    <WebLayout pageName={"Profile"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <Breadcrumb mainTitle={"Profile"} subTitle={"Profile"} />
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12">
              <div className="card">
                <div className="profile-tab-box">
                  <ul className="nav ">
                    <li className="nav-item tab-all">
                      <a
                        className={`nav-link ${activeTab === "basicDetails" ? "active show" : ""}`}
                        href="#basicDetails"
                        onClick={(event) =>
                          handleTabClick(event, "basicDetails")
                        }
                      >
                        BASIC DETAILS
                      </a>
                    </li>
                    <li className="nav-item tab-all p-l-20">
                      <a
                        className={`nav-link ${activeTab === "kyc" ? "active show" : ""}`}
                        href="#kyc"
                        onClick={(event) => handleTabClick(event, "kyc")}
                      >
                        KYC DOCUMENT
                      </a>
                    </li>
                    <li className="nav-item tab-all p-l-20">
                      <a
                        className={`nav-link ${activeTab === "bankDetails" ? "active show" : ""}`}
                        href="#bankDetails"
                        onClick={(event) =>
                          handleTabClick(event, "bankDetails")
                        }
                      >
                        BANK ACCOUNT DETAILS
                      </a>
                    </li>
                    <li className="nav-item tab-all p-l-20">
                      <a
                        className={`nav-link ${activeTab === "changePassword" ? "active show" : ""}`}
                        href="#changePassword"
                        onClick={(event) =>
                          handleTabClick(event, "changePassword")
                        }
                      >
                        CHANGE PASSWORD
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              {/* Employer Details Section */}
              <div className="card">
                <div className="m-b-20">
                  <div className="contact-grid">
                    <div className="profile-header bg-orange">
                      <div className="user-name">{employerDetails?.name}</div>
                      <div className="name-center">
                        {employerDetails?.type_of_company}
                      </div>
                    </div>
                    <ImageComponent
                      sourceImage={employerDetails?.company_image}
                      width={80}
                      height={80}
                      altTitle={"Company Image"}
                    />
                    <p>{employerDetails?.address}</p>
                    <div>
                      <span className="phone" style={{ fontSize: 17 }}>
                        <i className="material-icons">phone</i>
                        {employerDetails?.mobile}
                      </span>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <h5>{employerDetails?.emp_no}</h5>
                        <small>Employer Code</small>
                      </div>
                      <div className="col-4">
                        <h5>{employerDetails?.no_of_employee}</h5>
                        <small>Total Employees</small>
                      </div>
                      <div className="col-4">
                        <h5>{employerDetails?.email}</h5>
                        <small>Email</small>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-4">
                        <h5>{employerDetails?.sName}</h5>
                        <small>State</small>
                      </div>
                      <div className="col-4">
                        <h5>{employerDetails?.dName}</h5>
                        <small>District</small>
                      </div>
                      <div className="col-4">
                        <h5>{employerDetails?.pincode}</h5>
                        <small>Pincode</small>
                      </div>
                    </div>
                    <div className={"row mt-3"}>
                      <div className={"col-12"}>
                        KYC STATUS &nbsp;
                        <div
                          className={`name-center label ${employerDetails?.kyc_status === "REJECTED" ? "label-danger" : employerDetails?.kyc_status === "SEND FOR APPROVAL" ? "label-info" : employerDetails?.kyc_status === "VERIFIED" ? "label-success" : "label-warning"}`}
                        >
                          {employerDetails?.kyc_status}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"col-lg-8 col-md-12"}>
              <div className="tab-content">
                <div
                  role="tabpanel"
                  className={`tab-pane ${activeTab === "basicDetails" ? "active" : ""}`}
                  id="basicDetails"
                >
                  <BasicDetails
                    editableData={employerDetails}
                    getEmployerData={getEmployerData}
                  />
                </div>
                <div
                  role="tabpanel"
                  className={`tab-pane ${activeTab === "kyc" ? "active" : ""}`}
                  id="kyc"
                >
                  {employerDetails?.kyc_status === "NOT_VERIFIED" ||
                  employerDetails.kyc_status === "REJECTED" ? (
                    <EditKYCDetails
                      editableData={employerDetails}
                      getEmployerData={getEmployerData}
                    />
                  ) : (
                    <ViewKYCDetails editableData={employerDetails} />
                  )}
                </div>
                <div
                  role="tabpanel"
                  className={`tab-pane ${activeTab === "bankDetails" ? "active" : ""}`}
                  id="bankDetails"
                >
                  <BankDetails
                    editableData={employerDetails}
                    getEmployerData={getEmployerData}
                  />
                </div>
                <div
                  role="tabpanel"
                  className={`tab-pane ${activeTab === "changePassword" ? "active" : ""}`}
                  id="changePassword"
                >
                  <ChangePassword editableData={employerDetails} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default Profile;
