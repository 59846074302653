import { ThunkAction } from "redux-thunk";
import { RootState } from "..";
import axios from "axios";
import { BASE_URL, EMPLOYER_TOKEN } from "../../utils/Constants";
import toast from "react-hot-toast";
import DepartmentType, {
  CreateDepartment,
  GetDepartment,
  RemoveDepartment,
  UpdateDepartment,
} from "../type/departmentType";
import LogoutAction from "./LogoutAction";

interface Action {
  type: string;
  payload?: any;
}

const DepartmentAction = {
  getDepartmentList:
    (data: GetDepartment): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = localStorage.getItem(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.post(
          BASE_URL + "department/list",
          {
            search: data.search,
            limit: data.limit,
            page: data.page,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "application/json",
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: DepartmentType.GET_DEPARTMENT_LIST,
            payload: {
              data: result.data.data.data,
              currentPage: result.data.data.current_page,
              lastPage: result.data.data.last_page,
              totalData: result.data.data.total,
              from: result.data.data.from,
              to: result.data.data.to,
            },
          });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },

  createDepartment:
    (data: CreateDepartment): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = localStorage.getItem(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.post(
          BASE_URL + "department/create",
          {
            name: data.name,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              "Content-Type": "multipart/form-data",
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: DepartmentType.CREATE_DEPARTMENT,
          });
          toast.success(result.data.msg);
        } else {
          const errorMsg = result.data.msg;
          toast.error(errorMsg, {
            className: "custom-toast",
          });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },

  updateDepartment:
    (data: UpdateDepartment): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = localStorage.getItem(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.post(
          BASE_URL + "department/update",
          {
            id: data.id,
            name: data.name,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: DepartmentType.UPDATE_DEPARTMENT,
          });
          toast.success(result.data.msg);
        } else {
          const errorMsg = result.data.msg;
          toast.error(errorMsg, {
            className: "custom-toast",
          });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },

  removeDepartment:
    (data: RemoveDepartment): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      const token: string | any = localStorage.getItem(EMPLOYER_TOKEN);

      if (!token) {
        toast.error("Unauthorized: No token found.");
        return;
      }

      try {
        const result: any = await axios.post(
          BASE_URL + "department/remove",
          {
            id: data.id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          },
        );

        if (result.data.status) {
          dispatch({
            type: DepartmentType.REMOVE_DEPARTMENT,
          });
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          dispatch(LogoutAction.logout());
        }
        const errorMsg =
          error.response?.data?.msg || error.message || "An error occurred";
        toast.error(errorMsg, {
          className: "custom-toast",
        });
      }
    },
};

export default DepartmentAction;
