import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import EmployerAction from "../../stores/action/employerAction";
import toast from "react-hot-toast";

type FormData = {
  accountHolderName: any;
  accountNumber: any;
  bankName: any;
  branchName: any;
  ifscCode: any;
};

function BankDetails({ editableData, getEmployerData }: any) {
  const dispatch = useDispatch<any>();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormData>();

  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    setValue("accountHolderName", editableData?.account_holder_name);
    setValue("accountNumber", editableData?.account_number);
    setValue("bankName", editableData?.bank_name);
    setValue("branchName", editableData?.branch_name);
    setValue("ifscCode", editableData?.ifsc_code);
  }, [editableData, setValue]);

  const formHandleSubmit = async (data: any) => {
    setBtnLoading(true);
    try {
      await dispatch(
        EmployerAction.updateBankDetails({
          accountHolderName: data.accountHolderName,
          accountNumber: data.accountNumber,
          bankName: data.bankName,
          branchName: data.branchName,
          ifscCode: data.ifscCode,
        }),
      );
      setBtnLoading(false);
      reset();
      getEmployerData();
    } catch (error: any) {
      setBtnLoading(false);
      toast.error(error, {
        className: "custom-toast",
      });
    }
  };

  return (
    <div className="row clearfix">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="card project_widget">
          <div className="header">
            <h2>BANK ACCOUNT DETAILS</h2>
          </div>
          <div className="body">
            <form
              className="needs-validation"
              onSubmit={handleSubmit(formHandleSubmit)}
              encType="multipart/form-data"
            >
              <div className="row clearfix">
                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label>
                      Account Holder Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Account Holder Name"
                      {...register("accountHolderName", { required: true })}
                    />
                    {errors.bankName && (
                      <span className="text-danger">
                        Account Holder Name is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label>
                      Bank Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Bank Name"
                      {...register("bankName", { required: true })}
                    />
                    {errors.bankName && (
                      <span className="text-danger">Bank Name is required</span>
                    )}
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label>
                      Account Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Account Number"
                      {...register("accountNumber", { required: true })}
                    />
                    {errors.accountNumber && (
                      <span className="text-danger">
                        Account Number is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label>
                      Branch Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Branch Name"
                      {...register("branchName", { required: true })}
                    />
                    {errors.branchName && (
                      <span className="text-danger">
                        Branch Name is required
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label>
                      IFSC Code <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="IFSC Code"
                      {...register("ifscCode", { required: true })}
                    />
                    {errors.ifscCode && (
                      <span className="text-danger">IFSC Code is required</span>
                    )}
                  </div>
                </div>

                <div className="col-md-12">
                  <button
                    className="btn btn-dark btn-round"
                    disabled={btnLoading}
                  >
                    {btnLoading ? (
                      <i className={"fa fa-spinner fa-spin"}></i>
                    ) : null}
                    &nbsp; Save Changes
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BankDetails;
