import WebLayout from "../../layout/WebLayout";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import swal from "sweetalert";
import Breadcrumb from "../../components/Breadcrumb";
import moment from "moment";
import TableFilter from "../../components/TableFilter";
import AddStaffDutyModal from "../../components/AddStaffDutyModal";
import StaffDutyAction from "../../stores/action/staffDutyAction";

function StaffDuty() {
  const [search, set_search] = useState<string>("");
  const [limit, set_limit] = useState<number>(10);
  const [page, set_page] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [editId, setEditId] = useState<number | null>(null); // State to track which row is being edited
  const [editedName, setEditedName] = useState<string>(""); // State for the new name input field
  const [editedStartTime, setEditedStartTime] = useState<string>(""); // State for the new name input field
  const [editedEndTime, setEditedEndTime] = useState<string>(""); // State for the new name input field

  const [modal, setModal] = useState<boolean>(false);

  const dispatch = useDispatch<any>();

  const { staffDutyList, lastPage, totalData, from, to } = useSelector(
    (state: RootState) => state.staffDuty,
  );

  const maxPagesToShow = 5;
  const pagination = [];

  const startPage = Math.max(page - Math.floor(maxPagesToShow / 2), 1);
  const endPage = Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getStaffDutyList = useCallback(async () => {
    await dispatch(
      StaffDutyAction.getStaffDutyList({
        limit: limit,
        search: search,
        page: page,
      }),
    );
  }, [dispatch, limit, search, page]);

  useEffect(() => {
    getStaffDutyList();
  }, [getStaffDutyList]);

  const handleRemove = async (id: number) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Cancel",
          value: null,
          visible: true,
          className: "cancel-button", // Custom class for styling
          closeModal: true,
        },
        confirm: {
          text: "Delete",
          value: true,
          visible: true,
          className: "delete-button", // Optional: Add a custom class for styling
          closeModal: true,
        },
      },
    });

    if (willDelete) {
      try {
        setLoading(true);
        await dispatch(StaffDutyAction.removeStaffDuty({ id }));
        swal("Your data has been deleted!", {
          icon: "success",
        });
        getStaffDutyList();
      } catch (error) {
        swal("Error!", "There was an issue deleting the data.", "error");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEdit = (
    id: number,
    currentName: string,
    startTime: string,
    endTime: string,
  ) => {
    setEditId(id); // Set the current row for editing
    setEditedName(currentName); // Set the current name in the input field
    setEditedStartTime(startTime); // Set the current start time in the input field
    setEditedEndTime(endTime); // Set the current end time in the input field
  };

  // Handle update
  const handleUpdate = async (id: number) => {
    try {
      setLoading(true);
      await dispatch(
        StaffDutyAction.updateStaffDuty({
          id: id,
          name: editedName,
          startTime: editedStartTime,
          endTime: editedEndTime,
        }),
      );
      setEditId(null); // Close the edit mode after updating
      getStaffDutyList();
    } catch (error) {
      console.error("Error updating staff duty:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <WebLayout pageName={"StaffDuty"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <Breadcrumb
                mainTitle={"Staff Duty"}
                subTitle={"Staff Schedule"}
              />
            </div>
          </div>
          <div className={"d-flex justify-content-between mb-2"}>
            <button className="btn btn-success" onClick={() => setModal(true)}>
              Add Staff Schedule <i className={"fa fa-plus iconFontSize"}></i>
            </button>
          </div>
          <div className="row">
            <AddStaffDutyModal
              modal={modal}
              setModal={setModal}
              getStaffDutyList={getStaffDutyList}
            />

            {/* StaffDuty List */}
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <TableFilter
                  limit={limit}
                  set_limit={set_limit}
                  search={search}
                  set_search={set_search}
                />
                <div className="body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th className={"ubuntu-medium"}>Name</th>
                          <th className={"ubuntu-medium"}>Start Time</th>
                          <th className={"ubuntu-medium"}>End Time</th>
                          <th className={"ubuntu-medium"}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {staffDutyList?.length > 0 ? (
                          staffDutyList.map((dataList: any, index: number) => (
                            <tr key={dataList.id}>
                              <td>{(page - 1) * limit + index + 1}</td>
                              <td>
                                {editId === dataList.id ? (
                                  <input
                                    type="text"
                                    value={editedName}
                                    onChange={(e) =>
                                      setEditedName(e.target.value)
                                    }
                                  />
                                ) : (
                                  dataList.shift_name
                                )}
                              </td>
                              <td>
                                {editId === dataList.id ? (
                                  <input
                                    type="time"
                                    value={editedStartTime}
                                    onChange={(e) =>
                                      setEditedStartTime(e.target.value)
                                    }
                                  />
                                ) : (
                                  dataList.start_time
                                )}
                              </td>
                              <td>
                                {editId === dataList.id ? (
                                  <input
                                    type="time"
                                    value={editedEndTime}
                                    onChange={(e) =>
                                      setEditedStartTime(e.target.value)
                                    }
                                  />
                                ) : (
                                  dataList.end_time
                                )}
                              </td>
                              <td>
                                {editId === dataList.id ? (
                                  <button
                                    className="btn btn-sm btn-success"
                                    onClick={() => handleUpdate(dataList.id)}
                                  >
                                    Update
                                  </button>
                                ) : (
                                  <>
                                    <button
                                      className="btn btn-sm btn-success"
                                      onClick={() =>
                                        handleEdit(
                                          dataList.id,
                                          dataList.shift_name,
                                          dataList.start_time,
                                          dataList.end_time,
                                        )
                                      }
                                    >
                                      Edit
                                    </button>
                                    &nbsp;
                                    <button
                                      className="btn btn-sm btn-danger"
                                      onClick={() => handleRemove(dataList.id)}
                                      disabled={loading}
                                    >
                                      {loading ? (
                                        <i className="fa fa-spinner fa-spin"></i>
                                      ) : (
                                        "Remove"
                                      )}
                                    </button>
                                  </>
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={5} className="text-center">
                              No matching records found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {staffDutyList?.length > 0 && (
                    <div className="row mt-2 justify-content-between">
                      <div className="col-md-auto me-auto">
                        <div className="dt-info">
                          Showing {from} to {to} of {totalData} entries
                        </div>
                      </div>
                      <div className="col-md-auto ms-auto">
                        <div className="dt-paging paging_full_numbers">
                          <ul className="pagination">
                            <li
                              className={`dt-paging-button page-item ${
                                page === 1 ? "disabled" : ""
                              }`}
                              onClick={() => page !== 1 && set_page(1)}
                            >
                              <a
                                className="page-link first"
                                aria-controls="tableGroup"
                                aria-disabled={page === 1}
                                aria-label="First"
                                data-dt-idx="first"
                              >
                                <span aria-hidden="true">&laquo;</span>
                              </a>
                            </li>

                            {/* Previous Page */}
                            <li
                              className={`dt-paging-button page-item prev ${
                                page === 1 ? "disabled" : ""
                              }`}
                              onClick={() => page !== 1 && set_page(page - 1)}
                            >
                              <a
                                className="page-link previous"
                                aria-controls="tableGroup"
                                aria-disabled={page === 1}
                                aria-label="Previous"
                                data-dt-idx="previous"
                              >
                                <span aria-hidden="true">&lt;</span>
                              </a>
                            </li>

                            {/* Page Numbers */}
                            {pagination.map((p: number) => (
                              <li
                                key={p}
                                className={`dt-paging-button page-item ${
                                  page === p ? "active" : ""
                                }`}
                                onClick={() => set_page(p)}
                              >
                                <a
                                  className="page-link"
                                  aria-controls="tableGroup"
                                  aria-current="page"
                                  data-dt-idx="0"
                                >
                                  {p}
                                </a>
                              </li>
                            ))}

                            {/* Next Page */}
                            <li
                              className={`dt-paging-button page-item next ${
                                page === lastPage ? "disabled" : ""
                              }`}
                              onClick={() =>
                                page !== lastPage && set_page(page + 1)
                              }
                            >
                              <a
                                className="page-link next"
                                aria-controls="tableGroup"
                                aria-label="Next"
                                data-dt-idx="next"
                              >
                                <span aria-hidden="true">&gt;</span>
                              </a>
                            </li>

                            {/* Last Page */}
                            <li
                              className={`dt-paging-button page-item ${
                                page === lastPage ? "disabled" : ""
                              }`}
                              onClick={() =>
                                page !== lastPage && set_page(lastPage)
                              }
                            >
                              <a
                                className="page-link last"
                                aria-controls="tableGroup"
                                aria-disabled={page === lastPage}
                                aria-label="Last"
                                data-dt-idx="last"
                              >
                                <span aria-hidden="true">&raquo;</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default StaffDuty;
