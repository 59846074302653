import { useCallback, useEffect, useState } from "react";
import ProfileAction from "../../stores/action/profileAction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import WebAction from "../../stores/action/webAction";

// Define TypeScript interfaces for props and state
interface UpdateProfileProps {
  modal: boolean;
  setModal: (value: boolean) => void;
  modalType: any;
  profileData: any; // Replace 'any' with your actual type
  getProfileData: () => Promise<void>;
}

function UpdateProfile({
  modal,
  setModal,
  modalType,
  profileData,
  getProfileData,
}: UpdateProfileProps) {
  const dispatch = useDispatch<any>();

  // Loading state
  const [loading, setLoading] = useState<boolean>(false);

  // Profile Details State
  const [profileImage, setProfileImage] = useState<File | string>("");
  const [name, setName] = useState<string>("");
  const [fatherName, setFatherName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [stateName, setStateName] = useState<string>("");
  const [district, setDistrict] = useState<string>("");
  const [pinCode, setPinCode] = useState<string>("");
  const [presentAddress, setPresentAddress] = useState<string>("");
  const [permanentAddress, setPermanentAddress] = useState<string>("");

  // Bank Details State
  const [accountHolderName, setAccountHolderName] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [ifscCode, setIFSCCode] = useState<string>("");
  const [bankName, setBankName] = useState<string>("");
  const [branchName, setBranchName] = useState<string>("");

  // KYC Details State
  const [panImage, setPanImage] = useState<File | string>("");
  const [panNo, setPanNo] = useState<string>("");
  const [aadhaarImage, setAadhaarImage] = useState<File | string>("");
  const [aadhaarNo, setAadhaarNo] = useState<string>("");

  const { stateList, districtList } = useSelector(
    (state: RootState) => state.web,
  );

  // Set initial form values from profileData
  useEffect(() => {
    if (profileData) {
      setProfileImage(profileData.profile_img || "");
      setName(profileData.name || "");
      setFatherName(profileData.f_name || "");
      setEmail(profileData.email || "");
      setMobile(profileData.mobile || "");
      setStateName(profileData.state ? profileData.state.toString() : "");
      setDistrict(profileData.district ? profileData.district.toString() : "");
      setPinCode(profileData.pincode || "");
      setPresentAddress(profileData.present_address || "");
      setPermanentAddress(profileData.permanent_address || "");
      setAccountHolderName(profileData.account_holder_name || "");
      setAccountNumber(profileData.account_number || "");
      setIFSCCode(profileData.ifsc_code || "");
      setBankName(profileData.bank_name || "");
      setBranchName(profileData.branch_name || "");
      setPanImage(profileData.pan_img || "");
      setPanNo(profileData.pan_no || "");
      setAadhaarImage(profileData.aadhar_img || "");
      setAadhaarNo(profileData.aadhar_no || "");
    }
  }, [profileData]);

  // Handle File Inputs
  const handleProfileImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setProfileImage(file);
    }
  };

  const handlePanImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setPanImage(file);
    }
  };

  const handleAadharImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setAadhaarImage(file);
    }
  };

  // Submit Handlers
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      await dispatch(
        ProfileAction.updateProfile({
          id: profileData.id,
          profile_img: profileImage,
          name: name,
          f_name: fatherName,
          email: email,
          mobile: mobile,
          state: stateName ? parseInt(stateName, 10) : 0,
          district: district ? parseInt(district, 10) : 0,
          pincode: pinCode,
          present_address: presentAddress,
          permanent_address: permanentAddress,
        }),
      );
      setLoading(false);
      setModal(false);
      getProfileData();
    } catch (error) {
      console.error("Error updating profile:", error);
      setLoading(false);
      // Optionally, display an error message to the user
    }
  };

  const handleBankDetailsSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      await dispatch(
        ProfileAction.updateBankDetails({
          id: profileData.id,
          account_holder_name: accountHolderName,
          account_number: accountNumber,
          bank_name: bankName,
          branch_name: branchName,
          ifsc_code: ifscCode,
        }),
      );
      setLoading(false);
      setModal(false);
      getProfileData();
    } catch (error) {
      console.error("Error updating bank details:", error);
      setLoading(false);
      // Optionally, display an error message to the user
    }
  };

  const handleKYCDetailsSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      await dispatch(
        ProfileAction.updateKYCDetails({
          id: profileData.id,
          pan_img: panImage,
          pan_no: panNo,
          aadhar_img: aadhaarImage,
          aadhar_no: aadhaarNo,
        }),
      );
      setLoading(false);
      setModal(false);
      getProfileData();
    } catch (error) {
      console.error("Error updating KYC details:", error);
      setLoading(false);
      // Optionally, display an error message to the user
    }
  };

  // Close Steps
  const closeModal = () => {
    setModal(false);
  };

  // Fetch State and District Data
  const getStateData = useCallback(async () => {
    await dispatch(WebAction.getState());
  }, [dispatch]);

  const getDistrictData = useCallback(async () => {
    if (stateName) {
      await dispatch(
        WebAction.getDistrict({
          state_id: parseInt(stateName, 10),
        }),
      );
    }
  }, [dispatch, stateName]);

  useEffect(() => {
    getStateData();
    getDistrictData();
  }, [getStateData, getDistrictData]);

  return (
    <div className="modal" style={{ display: modal ? "block" : "none" }}>
      <div className="modal-dialog" role="document">
        {modalType === "profile" ? (
          <form
            className="needs-validation"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-dark">
                  Update Personal Details
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  {/* Profile Image */}
                  <div className="col-md-6">
                    <label>Upload Profile Image</label>
                    <div className="form-group">
                      <input
                        type="file"
                        className="form-control"
                        onChange={handleProfileImageChange}
                        accept="image/*"
                      />
                    </div>
                  </div>

                  {/* Name */}
                  <div className="col-md-6">
                    <label>Name</label>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Name"
                        required
                      />
                    </div>
                  </div>

                  {/* Father Name */}
                  <div className="col-md-6">
                    <label>Father Name</label>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        value={fatherName}
                        onChange={(e) => setFatherName(e.target.value)}
                        placeholder="Father Name"
                        required
                      />
                    </div>
                  </div>

                  {/* Email Id */}
                  <div className="col-md-6">
                    <label>Email Id</label>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email Id"
                        required
                      />
                    </div>
                  </div>

                  {/* Mobile Number */}
                  <div className="col-md-6">
                    <label>Mobile No.</label>
                    <div className="form-group">
                      <input
                        type="tel"
                        className="form-control"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        placeholder="Mobile No."
                        required
                      />
                    </div>
                  </div>

                  {/* Select State */}
                  <div className="col-md-6">
                    <label>Select State</label>
                    <div className="form-group">
                      <select
                        value={stateName}
                        onChange={(e) => setStateName(e.target.value)}
                        className="form-control"
                        required
                      >
                        <option value="" disabled selected>
                          Select State
                        </option>
                        {stateList &&
                          stateList.map((stateData: any) => (
                            <option
                              key={stateData.id}
                              value={stateData.id.toString()}
                            >
                              {stateData.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  {/* Select District */}
                  <div className="col-md-6">
                    <label>Select District</label>
                    <div className="form-group">
                      <select
                        value={district}
                        onChange={(e) => setDistrict(e.target.value)}
                        className="form-control"
                        required
                        disabled={!stateName}
                      >
                        <option value="" disabled selected>
                          Select District
                        </option>
                        {districtList &&
                          districtList.map((districtData: any) => (
                            <option
                              key={districtData.id}
                              value={districtData.id.toString()}
                            >
                              {districtData.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  {/* Pin Code */}
                  <div className="col-md-6">
                    <label>Pin Code</label>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        value={pinCode}
                        onChange={(e) => setPinCode(e.target.value)}
                        placeholder="Pin Code"
                        required
                        pattern="[0-9]{6}"
                        title="Please enter a valid 6-digit pin code"
                      />
                    </div>
                  </div>

                  {/* Present Address */}
                  <div className="col-md-12">
                    <label>Present Address</label>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        value={presentAddress}
                        onChange={(e) => setPresentAddress(e.target.value)}
                        placeholder="Present Address"
                        required
                      />
                    </div>
                  </div>

                  {/* Permanent Address */}
                  <div className="col-md-12">
                    <label>Permanent Address</label>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        value={permanentAddress}
                        onChange={(e) => setPermanentAddress(e.target.value)}
                        placeholder="Permanent Address"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger light"
                  disabled={loading}
                  onClick={closeModal}
                >
                  Close
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className="btn btn-primary px-4"
                >
                  {loading ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </div>
          </form>
        ) : modalType === "bank" ? (
          <form
            className="needs-validation"
            onSubmit={handleBankDetailsSubmit}
            encType="multipart/form-data"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-dark">Update Bank Details</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  {/* Account Holder Name */}
                  <div className="col-md-6">
                    <label>Account Holder Name</label>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        value={accountHolderName}
                        onChange={(e) => setAccountHolderName(e.target.value)}
                        placeholder="Account Holder Name"
                        required
                      />
                    </div>
                  </div>

                  {/* Account Number */}
                  <div className="col-md-6">
                    <label>Account Number</label>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                        placeholder="Account Number"
                        required
                        pattern="[0-9]{9,18}"
                        title="Please enter a valid account number (9-18 digits)"
                      />
                    </div>
                  </div>

                  {/* Bank Name */}
                  <div className="col-md-6">
                    <label>Bank Name</label>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        value={bankName}
                        onChange={(e) => setBankName(e.target.value)}
                        placeholder="Bank Name"
                        required
                      />
                    </div>
                  </div>

                  {/* IFSC Code */}
                  <div className="col-md-6">
                    <label>IFSC Code</label>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        value={ifscCode}
                        onChange={(e) => setIFSCCode(e.target.value)}
                        placeholder="IFSC Code"
                        required
                      />
                    </div>
                  </div>

                  {/* Branch Name */}
                  <div className="col-md-6">
                    <label>Branch Name</label>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        value={branchName}
                        onChange={(e) => setBranchName(e.target.value)}
                        placeholder="Branch Name"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger light"
                  disabled={loading}
                  onClick={closeModal}
                >
                  Close
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className="btn btn-primary px-4"
                >
                  {loading ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </div>
          </form>
        ) : modalType === "kyc" ? (
          <form
            className="needs-validation"
            onSubmit={handleKYCDetailsSubmit}
            encType="multipart/form-data"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title text-dark">Update KYC Details</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeModal}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  {/* PAN Number */}
                  <div className="col-md-6">
                    <label>PAN Number</label>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        value={panNo}
                        onChange={(e) => setPanNo(e.target.value)}
                        placeholder="PAN Number"
                        required
                      />
                    </div>
                  </div>

                  {/* PAN Image */}
                  <div className="col-md-6">
                    <label>Upload PAN Image</label>
                    <div className="form-group">
                      <input
                        type="file"
                        className="form-control"
                        onChange={handlePanImageChange}
                        accept="image/*"
                        required
                      />
                      {/* Preview PAN Image */}
                      {panImage && typeof panImage === "string" && (
                        <img
                          src={panImage}
                          alt="PAN Preview"
                          className="mt-2"
                          style={{ width: "100%", height: "auto" }}
                        />
                      )}
                      {panImage && panImage instanceof File && (
                        <img
                          src={URL.createObjectURL(panImage)}
                          alt="PAN Preview"
                          className="mt-2"
                          style={{ width: "100%", height: "auto" }}
                        />
                      )}
                    </div>
                  </div>

                  {/* Aadhaar Number */}
                  <div className="col-md-6">
                    <label>Aadhaar Number</label>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        value={aadhaarNo}
                        onChange={(e) => setAadhaarNo(e.target.value)}
                        placeholder="Aadhaar Number"
                        required
                      />
                    </div>
                  </div>

                  {/* Aadhaar Image */}
                  <div className="col-md-6">
                    <label>Upload Aadhaar Image</label>
                    <div className="form-group">
                      <input
                        type="file"
                        className="form-control"
                        onChange={handleAadharImageChange}
                        accept="image/*"
                        required
                      />
                      {/* Preview Aadhaar Image */}
                      {aadhaarImage && typeof aadhaarImage === "string" && (
                        <img
                          src={aadhaarImage}
                          alt="Aadhaar Preview"
                          className="mt-2"
                          style={{ width: "100%", height: "auto" }}
                        />
                      )}
                      {aadhaarImage && aadhaarImage instanceof File && (
                        <img
                          src={URL.createObjectURL(aadhaarImage)}
                          alt="Aadhaar Preview"
                          className="mt-2"
                          style={{ width: "100%", height: "auto" }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger light"
                  disabled={loading}
                  onClick={closeModal}
                >
                  Close
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className="btn btn-primary px-4"
                >
                  {loading ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </div>
          </form>
        ) : null}
      </div>
    </div>
  );
}

export default UpdateProfile;
