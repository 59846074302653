import StaffDutyType from "../type/staffDutyType";

export interface ReducerAction {
  type: string;
  payload?: any;
}

const INITIAL_STATE = {
  staffDutyList: [],
  staffDutyRecordList: [],
  currentPage: 0,
  lastPage: 0,
  totalData: 0,
  from: 0,
  to: 0,
};

const HolidayReducers = (state = INITIAL_STATE, action: ReducerAction) => {
  switch (action.type) {
    case StaffDutyType.GET_STAFF_DUTY:
      return {
        ...state,
        staffDutyList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
      };

    case StaffDutyType.CREATE_STAFF_DUTY:
      return {
        ...state,
      };

    case StaffDutyType.UPDATE_STAFF_DUTY:
      return {
        ...state,
      };

    case StaffDutyType.REMOVE_STAFF_DUTY:
      return {
        ...state,
      };

    case StaffDutyType.STAFF_RECORD_DUTY:
      return {
        ...state,
        staffDutyRecordList: action.payload,
      };

    default:
      return state;
  }
};

export default HolidayReducers;
