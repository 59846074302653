import React, { useEffect, useCallback, useState } from "react";
import WebLayout from "../../layout/WebLayout";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import WebAction from "../../stores/action/webAction";
import { Link } from "react-router-dom";
import { today } from "../../utils/Constants";
import EmployeeAction from "../../stores/action/employeeAction";
import Breadcrumb from "../../components/Breadcrumb";
import SalaryAction from "../../stores/action/salaryAction";

type FormData = {
  image: File | null;
  name: string;
  fatherName: string;
  dateOfBirth: string;
  phone: string;
  state: number;
  district: number;
  pinCode: string;
  correspondenceAddress: string;
  permanentAddress: string;
  email: string;
  password: string;
  employeeID: string;
  department: string;
  designation: number;
  dateOfJoining: string;
  joiningSalary: string;
  accountHolderName: string;
  accountNumber: string;
  bankName: string;
  ifscCode: string;
  branch: string;
};

function CreateEmployee() {
  const dispatch = useDispatch<any>();
  const {
    stateList,
    districtList,
    employeeDepartmentList,
    employeeDesignationList,
  } = useSelector((state: RootState) => state.web);

  const { salaryStructure } = useSelector((state: RootState) => state.salary);

  // Form state
  const [formData, setFormData] = useState<FormData>({
    image: null,
    name: "",
    fatherName: "",
    dateOfBirth: "",
    phone: "",
    state: 0,
    district: 0,
    pinCode: "",
    correspondenceAddress: "",
    permanentAddress: "",
    email: "",
    password: "",
    employeeID: "",
    department: "",
    designation: 0,
    dateOfJoining: "",
    joiningSalary: "",
    accountHolderName: "",
    accountNumber: "",
    bankName: "",
    ifscCode: "",
    branch: "",
  });

  // Error state
  const [errors, setErrors] = useState<{ [key in keyof FormData]?: string }>(
    {},
  );

  const [btnLoading, setBtnLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const getStates = useCallback(async () => {
    await dispatch(WebAction.getState());
  }, [dispatch]);

  const getDistrict = useCallback(
    async (state_id: number) => {
      try {
        await dispatch(WebAction.getDistrict({ state_id }));
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    getStates();
    dispatch(WebAction.getDepartment());
    dispatch(WebAction.getDesignation());
    dispatch(SalaryAction.getSalaryStructure());
  }, [dispatch, getStates]);

  const handleStateChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const stateId = parseInt(e.target.value, 10);
    setFormData((prev) => ({ ...prev, state: stateId, district: 0 }));
    await getDistrict(stateId);
  };

  const getStepFields = (step: number): Array<keyof FormData> => {
    switch (step) {
      case 1:
        return [
          "image",
          "name",
          "fatherName",
          "dateOfBirth",
          "phone",
          "state",
          "district",
          "pinCode",
          "correspondenceAddress",
          "permanentAddress",
        ];
      case 2:
        return ["email", "password"];
      case 3:
        return [
          "employeeID",
          "department",
          "designation",
          "dateOfJoining",
          "joiningSalary",
        ];
      case 4:
        return [
          "accountHolderName",
          "accountNumber",
          "bankName",
          "ifscCode",
          "branch",
        ];
      default:
        return [];
    }
  };

  const validateStep = (step: number): boolean => {
    const stepFields = getStepFields(step);
    const newErrors: { [key in keyof FormData]?: string } = {};

    stepFields.forEach((field) => {
      const value: any = formData[field];
      switch (field) {
        case "image":
          if (!value) {
            newErrors.image = "Image is required";
          } else {
            const acceptedFormats = ["image/jpeg", "image/png", "image/gif"];
            if (!acceptedFormats.includes((value as File).type)) {
              newErrors.image = "Only JPEG, PNG, or GIF files are allowed";
            }
            if ((value as File).size > 2 * 1024 * 1024) {
              newErrors.image = "Image size should not exceed 2MB";
            }
          }
          break;
        case "name":
          if (!value.trim()) newErrors.name = "Name is required";
          break;
        case "fatherName":
          if (!value.trim()) newErrors.fatherName = "Father's Name is required";
          break;
        case "dateOfBirth":
          if (!value) {
            newErrors.dateOfBirth = "Date of Birth is required";
          } else if (value > today) {
            newErrors.dateOfBirth = "Date of Birth cannot be in the future";
          }
          break;
        case "phone":
          if (!value) {
            newErrors.phone = "Phone is required";
          } else if (!/^[0-9]{10}$/.test(value)) {
            newErrors.phone = "Phone must be 10 digits";
          }
          break;
        case "state":
          if (!value) newErrors.state = "State is required";
          break;
        case "district":
          if (!value) newErrors.district = "District is required";
          break;
        case "pinCode":
          if (!value) {
            newErrors.pinCode = "Pincode is required";
          } else if (!/^[0-9]{6}$/.test(value)) {
            newErrors.pinCode = "Pincode must be 6 digits";
          }
          break;
        case "correspondenceAddress":
          if (!value.trim())
            newErrors.correspondenceAddress =
              "Correspondence Address is required";
          break;
        case "permanentAddress":
          if (!value.trim())
            newErrors.permanentAddress = "Permanent Address is required";
          break;
        case "email":
          if (!value) {
            newErrors.email = "Email is required";
          } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
            newErrors.email = "Invalid email address";
          }
          break;
        case "password":
          if (!value) {
            newErrors.password = "Password is required";
          } else if ((value as string).length < 6) {
            newErrors.password = "Password must be at least 6 characters";
          }
          break;
        case "employeeID":
          if (!value.trim()) {
            newErrors.employeeID = "Employee ID is required";
          }
          break;
        case "department":
          if (!value) {
            newErrors.department = "Department is required";
          }
          break;
        case "designation":
          if (!value) {
            newErrors.designation = "StaffDuty is required";
          }
          break;
        case "dateOfJoining":
          if (!value) {
            newErrors.dateOfJoining = "Date of Joining is required";
          } else if (value > today) {
            newErrors.dateOfJoining = "Date of Joining cannot be in the future";
          }
          break;
        case "joiningSalary":
          if (!value) {
            newErrors.joiningSalary = "Joining Salary is required";
          } else if (Number(value) < 0) {
            newErrors.joiningSalary =
              "Joining Salary must be a positive number";
          }
          break;
        case "accountHolderName":
          if (!value.trim()) {
            newErrors.accountHolderName = "Account Holder Name is required";
          }
          break;
        case "accountNumber":
          if (!value) {
            newErrors.accountNumber = "Account Number is required";
          } else if (!/^[0-9]{9,18}$/.test(value)) {
            newErrors.accountNumber =
              "Account Number must be between 9 to 18 digits";
          }
          break;
        case "bankName":
          if (!value.trim()) {
            newErrors.bankName = "Bank Name is required";
          }
          break;
        case "ifscCode":
          if (!value) {
            newErrors.ifscCode = "IFSC Code is required";
          } else if (!/^[A-Z]{4}0[A-Z0-9]{6}$/.test(value)) {
            newErrors.ifscCode = "Invalid IFSC Code";
          }
          break;
        case "branch":
          if (!value.trim()) {
            newErrors.branch = "Branch is required";
          }
          break;
        default:
          break;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setBtnLoading(true);

    const isValid = validateStep(currentStep);
    if (!isValid) {
      setBtnLoading(false);
      return;
    }

    // If on the last step, submit the form
    if (currentStep === 4) {
      // Perform final validation for all steps
      let allValid = true;
      for (let step = 1; step <= 4; step++) {
        if (!validateStep(step)) {
          allValid = false;
          setCurrentStep(step);
          break;
        }
      }

      if (!allValid) {
        setBtnLoading(false);
        return;
      }

      try {
        await dispatch(EmployeeAction.createEmployee(formData));

        // Reset form
        setFormData({
          image: null,
          name: "",
          fatherName: "",
          dateOfBirth: "",
          phone: "",
          state: 0,
          district: 0,
          pinCode: "",
          correspondenceAddress: "",
          permanentAddress: "",
          email: "",
          password: "",
          employeeID: "",
          department: "",
          designation: 0,
          dateOfJoining: "",
          joiningSalary: "",
          accountHolderName: "",
          accountNumber: "",
          bankName: "",
          ifscCode: "",
          branch: "",
        });
        setCurrentStep(1);
        setErrors({});
      } catch (error) {
        console.error("Submission Error:", error);
        // Optionally, set a global error message here
      } finally {
        setBtnLoading(false);
      }
    } else {
      // Move to the next step
      const nextStepNumber = currentStep + 1;
      setCurrentStep(nextStepNumber);
      setBtnLoading(false);
    }
  };

  const nextStep = () => {
    const isValid = validateStep(currentStep);
    if (isValid) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const prevStep = () => {
    setCurrentStep((prev) => prev - 1);
  };

  // Calculate total allowances
  const totalAllowances = salaryStructure?.allowances
    ? JSON.parse(salaryStructure?.allowances)?.reduce(
        (acc: any, allowance: any) => {
          return (
            acc + (Number(formData.joiningSalary) * allowance.amount) / 100
          );
        },
        0,
      )
    : 0;

  // Calculate total deductions
  const totalDeductions = salaryStructure?.deductions
    ? JSON.parse(salaryStructure?.deductions)?.reduce(
        (acc: any, deduction: any) => {
          return (
            acc + (Number(formData.joiningSalary) * deduction.amount) / 100
          );
        },
        0,
      )
    : 0;

  // Calculate ESIC if the basic salary is below the ESIC limit
  const esicAmount =
    Number(formData.joiningSalary) <= salaryStructure?.esic_limit
      ? (Number(formData.joiningSalary) * salaryStructure?.esic) / 100
      : 0;

  // Calculate PF
  const pfAmount = (Number(formData.joiningSalary) * salaryStructure?.pf) / 100;

  // Calculate net salary
  const netSalary =
    Number(formData.joiningSalary) +
    totalAllowances -
    totalDeductions -
    esicAmount -
    pfAmount;

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <div className="row clearfix">
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Image <span className="text-danger">*</span>
                  </label>
                  <input
                    type="file"
                    className="form-control"
                    accept="image/*"
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        image: e.target.files ? e.target.files[0] : null,
                      }))
                    }
                  />
                  {errors.image && (
                    <span className="text-danger">{errors.image}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    value={formData.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                  />
                  {errors.name && (
                    <span className="text-danger">{errors.name}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Father's Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Father's Name"
                    value={formData.fatherName}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        fatherName: e.target.value,
                      })
                    }
                  />
                  {errors.fatherName && (
                    <span className="text-danger">{errors.fatherName}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Date Of Birth <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    value={formData.dateOfBirth}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        dateOfBirth: e.target.value,
                      })
                    }
                    max={`${today}`}
                  />
                  {errors.dateOfBirth && (
                    <span className="text-danger">{errors.dateOfBirth}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Phone <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone"
                    value={formData.phone}
                    onChange={(e) =>
                      setFormData({ ...formData, phone: e.target.value })
                    }
                    maxLength={10}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors.phone && (
                    <span className="text-danger">{errors.phone}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    State <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    value={formData.state || ""}
                    onChange={handleStateChange}
                  >
                    <option value="" disabled>
                      Select State
                    </option>
                    {stateList?.map((val: any, index: number) => (
                      <option key={index} value={val.id}>
                        {val.name}
                      </option>
                    ))}
                  </select>
                  {errors.state && (
                    <span className="text-danger">{errors.state}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    District <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    value={formData.district || ""}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        district: parseInt(e.target.value, 10),
                      })
                    }
                  >
                    <option value="" disabled>
                      Select District
                    </option>
                    {districtList?.map((val: any, index: number) => (
                      <option key={index} value={val.id}>
                        {val.name}
                      </option>
                    ))}
                  </select>
                  {errors.district && (
                    <span className="text-danger">{errors.district}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Pincode <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Pincode"
                    value={formData.pinCode}
                    onChange={(e) =>
                      setFormData({ ...formData, pinCode: e.target.value })
                    }
                    maxLength={6}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors.pinCode && (
                    <span className="text-danger">{errors.pinCode}</span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Correspondence Address{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <textarea
                    rows={4}
                    className="form-control no-resize"
                    placeholder="Correspondence Address"
                    value={formData.correspondenceAddress}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        correspondenceAddress: e.target.value,
                      })
                    }
                  ></textarea>
                  {errors.correspondenceAddress && (
                    <span className="text-danger">
                      {errors.correspondenceAddress}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Permanent Address <span className="text-danger">*</span>
                  </label>
                  <textarea
                    rows={4}
                    className="form-control no-resize"
                    placeholder="Permanent Address"
                    value={formData.permanentAddress}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        permanentAddress: e.target.value,
                      })
                    }
                  ></textarea>
                  {errors.permanentAddress && (
                    <span className="text-danger">
                      {errors.permanentAddress}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="row clearfix">
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="E-mail"
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                  {errors.email && (
                    <span className="text-danger">{errors.email}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group" style={{ position: "relative" }}>
                  <label>
                    Password <span className="text-danger">*</span>
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    placeholder="Password"
                    value={formData.password}
                    onChange={(e) =>
                      setFormData({ ...formData, password: e.target.value })
                    }
                  />
                  {errors.password && (
                    <span className="text-danger">{errors.password}</span>
                  )}
                  <span
                    onClick={togglePasswordVisibility}
                    style={{
                      position: "absolute",
                      right: "10px",
                      bottom: "10%",
                      cursor: "pointer",
                      color: "gray",
                    }}
                  >
                    {showPassword ? (
                      <i className="fa fa-eye-slash"></i>
                    ) : (
                      <i className="fa fa-eye"></i>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="row clearfix">
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Employee ID <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Employee ID"
                    value={formData.employeeID}
                    onChange={(e) =>
                      setFormData({ ...formData, employeeID: e.target.value })
                    }
                  />
                  {errors.employeeID && (
                    <span className="text-danger">{errors.employeeID}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Department <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    value={formData.department}
                    onChange={(e) =>
                      setFormData({ ...formData, department: e.target.value })
                    }
                  >
                    <option value="" disabled>
                      Select Department
                    </option>
                    {employeeDepartmentList?.map((val: any, index: number) => (
                      <option key={index} value={val.id}>
                        {val.name}
                      </option>
                    ))}
                  </select>
                  {errors.department && (
                    <span className="text-danger">{errors.department}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Designation <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    value={formData.designation || ""}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        designation: parseInt(e.target.value, 10),
                      })
                    }
                  >
                    <option value="" disabled>
                      Select Designation
                    </option>
                    {employeeDesignationList?.map((val: any, index: number) => (
                      <option key={index} value={val.id}>
                        {val.name}
                      </option>
                    ))}
                  </select>
                  {errors.designation && (
                    <span className="text-danger">{errors.designation}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Date Of Joining <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    value={formData.dateOfJoining}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        dateOfJoining: e.target.value,
                      })
                    }
                    max={`${today}`}
                  />
                  {errors.dateOfJoining && (
                    <span className="text-danger">{errors.dateOfJoining}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Joining Salary <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Joining Salary"
                    value={formData.joiningSalary}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        joiningSalary: e.target.value,
                      })
                    }
                    min="0"
                  />
                  {errors.joiningSalary && (
                    <span className="text-danger">{errors.joiningSalary}</span>
                  )}
                </div>
              </div>

              <div>
                <h2>Salary Calculation</h2>
                <p>Basic Salary: {Number(formData?.joiningSalary)}</p>
                <p>Total Allowances: {totalAllowances?.toFixed(2)}</p>
                <p>Total Deductions: {totalDeductions?.toFixed(2)}</p>
                <p>ESIC: {esicAmount?.toFixed(2)}</p>
                <p>PF: {pfAmount?.toFixed(2)}</p>
                <h3>Net Salary: {netSalary?.toFixed(2)}</h3>
              </div>
            </div>
          </>
        );
      case 4:
        return (
          <>
            <div className="row clearfix">
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Account Holder Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Account Holder Name"
                    value={formData.accountHolderName}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        accountHolderName: e.target.value,
                      })
                    }
                  />
                  {errors.accountHolderName && (
                    <span className="text-danger">
                      {errors.accountHolderName}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Account Number <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Account Number"
                    value={formData.accountNumber}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        accountNumber: e.target.value,
                      })
                    }
                    maxLength={18}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors.accountNumber && (
                    <span className="text-danger">{errors.accountNumber}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Bank Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Bank Name"
                    value={formData.bankName}
                    onChange={(e) =>
                      setFormData({ ...formData, bankName: e.target.value })
                    }
                  />
                  {errors.bankName && (
                    <span className="text-danger">{errors.bankName}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    IFSC Code <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="IFSC Code"
                    value={formData.ifscCode}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        ifscCode: e.target.value.toUpperCase(),
                      })
                    }
                    maxLength={11}
                    onKeyPress={(e) => {
                      const allowed = /[A-Z0-9]/.test(e.key);
                      if (!allowed) {
                        e.preventDefault();
                      }
                    }}
                    style={{ textTransform: "uppercase" }}
                  />
                  {errors.ifscCode && (
                    <span className="text-danger">{errors.ifscCode}</span>
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="form-group">
                  <label>
                    Branch <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Branch"
                    value={formData.branch}
                    onChange={(e) =>
                      setFormData({ ...formData, branch: e.target.value })
                    }
                  />
                  {errors.branch && (
                    <span className="text-danger">{errors.branch}</span>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <WebLayout pageName="CreateEmployee">
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <Breadcrumb
                mainTitle={"ASL Account"}
                subTitle={"Create ASL Account (Employee)"}
              />
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12">
              <div className="card">
                <div className="profile-tab-box">
                  <ul className="nav">
                    {[1, 2, 3, 4].map((step) => (
                      <li
                        key={step}
                        className={`nav-item tab-all ${
                          currentStep >= step ? "active-step" : ""
                        }`}
                        style={{ width: `${100 / 4}%`, textAlign: "center" }}
                      >
                        <button
                          type="button"
                          className="nav-link"
                          disabled={currentStep < step}
                          onClick={() => setCurrentStep(step)}
                        >
                          {`${step}. ${
                            step === 1
                              ? "PERSONAL DETAILS"
                              : step === 2
                                ? "ACCOUNT LOGIN"
                                : step === 3
                                  ? "COMPANY DETAILS"
                                  : "BANK ACCOUNT DETAILS"
                          }`}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="card project_widget">
                <div className="header">
                  <h2>
                    {currentStep === 1 && "PERSONAL DETAILS"}
                    {currentStep === 2 && "ACCOUNT LOGIN"}
                    {currentStep === 3 && "COMPANY DETAILS"}
                    {currentStep === 4 && "BANK ACCOUNT DETAILS"}
                  </h2>
                </div>
                <div className="body">
                  <form onSubmit={onSubmit} encType="multipart/form-data">
                    {renderStep()}
                    <div className="col-md-12 d-flex justify-content-between mt-3">
                      {currentStep > 1 && (
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={prevStep}
                          disabled={btnLoading}
                        >
                          Previous
                        </button>
                      )}
                      {currentStep < 4 && (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={nextStep}
                          disabled={btnLoading}
                        >
                          Next
                        </button>
                      )}
                      {currentStep === 4 && (
                        <button
                          type="submit"
                          className="btn btn-success"
                          disabled={btnLoading}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default CreateEmployee;
