import WebLayout from "../../layout/WebLayout";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import swal from "sweetalert";
import HolidayAction from "../../stores/action/holidayAction";
import Breadcrumb from "../../components/Breadcrumb";
import moment from "moment";
import TableFilter from "../../components/TableFilter";
import { useForm } from "react-hook-form";
import EmployeeAction from "../../stores/action/employeeAction";
import StaffDutyAction from "../../stores/action/staffDutyAction";

type FormData = {
  name: string;
};

function AssignStaffDuty() {
  const [search, set_search] = useState<string>("");
  const [limit, set_limit] = useState<number>(10);
  const [page, set_page] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [editId, setEditId] = useState<number | null>(null); // State to track which row is being edited
  const [editedName, setEditedName] = useState<string>(""); // State for the new name input field
  const [editedDate, setEditedDate] = useState<string>(""); // State for the new name input field

  const dispatch = useDispatch<any>();

  const methods = useForm<FormData>({
    defaultValues: {
      name: "",
    },
  });

  const { employeeRecordList } = useSelector(
    (state: RootState) => state.employee,
  );

  const { staffDutyRecordList } = useSelector(
    (state: RootState) => state.staffDuty,
  );

  const maxPagesToShow = 5;
  const pagination = [];

  const startPage = Math.max(page - Math.floor(maxPagesToShow / 2), 1);
  const endPage = 0; //Math.min(startPage + maxPagesToShow - 1, lastPage);

  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  const getDataList = useCallback(async () => {
    await dispatch(EmployeeAction.getEmployeeRecordList());
    await dispatch(StaffDutyAction.getStaffDutyRecordList());
  }, [dispatch]);

  useEffect(() => {
    getDataList();
  }, [getDataList]);

  const handleRemove = async (id: number) => {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: {
          text: "Cancel",
          value: null,
          visible: true,
          className: "cancel-button", // Custom class for styling
          closeModal: true,
        },
        confirm: {
          text: "Delete",
          value: true,
          visible: true,
          className: "delete-button", // Optional: Add a custom class for styling
          closeModal: true,
        },
      },
    });

    if (willDelete) {
      try {
        setLoading(true);
        await dispatch(HolidayAction.removeHoliday({ id }));
        swal("Your data has been deleted!", {
          icon: "success",
        });
        // getHolidayList();
      } catch (error) {
        swal("Error!", "There was an issue deleting the data.", "error");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEdit = (id: number, currentName: string, currentDate: string) => {
    setEditId(id); // Set the current row for editing
    setEditedName(currentName); // Set the current name in the input field
    setEditedDate(currentDate); // Set the current name in the input field
  };

  // Handle update
  const handleUpdate = async (id: number) => {
    try {
      setLoading(true);
      await dispatch(
        HolidayAction.updateHoliday({
          id: id,
          name: editedName,
          date: editedDate,
        }),
      );
      setEditId(null); // Close the edit mode after updating
      // getHolidayList();
    } catch (error) {
      console.error("Error updating holiday:", error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      await dispatch(HolidayAction.markAllSunday());
      await dispatch(HolidayAction.checkSunday());
      // getHolidayList();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const {
    register,
    reset,
    formState: { errors },
  } = methods;

  return (
    <WebLayout pageName={"AssignStaffDuty"}>
      <section className="content">
        <div className="container-fluid">
          <div className="block-header">
            <div className="row">
              <Breadcrumb
                mainTitle={"Staff Duty"}
                subTitle={"Assign Staff Duty"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4">
              <div className="card">
                <div className="body">
                  <div className="row clearfix">
                    <form
                      className="needs-validation"
                      onSubmit={methods.handleSubmit(onSubmit)}
                      encType="multipart/form-data"
                    >
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label>
                            Leave Type Name{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            {...register("name", { required: true })}
                          />
                          {errors.name && (
                            <span className="text-danger">
                              Name is required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <button
                          type="submit"
                          className="btn btn-dark ml-auto"
                          disabled={loading}
                        >
                          {loading ? (
                            <i className="fa fa-spinner fa-spin"></i>
                          ) : (
                            "Save"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            {/* StaffDuty List */}
            <div className="col-lg-8 col-md-8 col-sm-8">
              <div className="card">
                <TableFilter
                  limit={limit}
                  set_limit={set_limit}
                  search={search}
                  set_search={set_search}
                />
                <div className="body">
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th className={"ubuntu-medium"}>Date</th>
                          <th className={"ubuntu-medium"}>Occasion</th>
                          <th className={"ubuntu-medium"}>Day</th>
                          <th className={"ubuntu-medium"}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/*{holidayList?.length > 0 ? (*/}
                        {/*  holidayList.map((dataList: any, index: number) => (*/}
                        {/*    <tr key={dataList.id}>*/}
                        {/*      <td>{(page - 1) * limit + index + 1}</td>*/}
                        {/*      <td>*/}
                        {/*        {editId === dataList.id ? (*/}
                        {/*          <input*/}
                        {/*            type="date"*/}
                        {/*            value={editedDate}*/}
                        {/*            onChange={(e) =>*/}
                        {/*              setEditedDate(e.target.value)*/}
                        {/*            }*/}
                        {/*          />*/}
                        {/*        ) : (*/}
                        {/*          moment(dataList.date).format("DD-MMM-YYYY")*/}
                        {/*        )}*/}
                        {/*      </td>*/}
                        {/*      <td>*/}
                        {/*        {editId === dataList.id ? (*/}
                        {/*          <input*/}
                        {/*            type="text"*/}
                        {/*            value={editedName}*/}
                        {/*            onChange={(e) =>*/}
                        {/*              setEditedName(e.target.value)*/}
                        {/*            }*/}
                        {/*          />*/}
                        {/*        ) : (*/}
                        {/*          dataList.name*/}
                        {/*        )}*/}
                        {/*      </td>*/}
                        {/*      <td>{moment(dataList.date).format("ddd")}</td>*/}
                        {/*      <td>*/}
                        {/*        {editId === dataList.id ? (*/}
                        {/*          <button*/}
                        {/*            className="btn btn-sm btn-success"*/}
                        {/*            onClick={() => handleUpdate(dataList.id)}*/}
                        {/*          >*/}
                        {/*            Update*/}
                        {/*          </button>*/}
                        {/*        ) : (*/}
                        {/*          <>*/}
                        {/*            <button*/}
                        {/*              className="btn btn-sm btn-success"*/}
                        {/*              onClick={() =>*/}
                        {/*                handleEdit(*/}
                        {/*                  dataList.id,*/}
                        {/*                  dataList.name,*/}
                        {/*                  dataList.date,*/}
                        {/*                )*/}
                        {/*              }*/}
                        {/*            >*/}
                        {/*              Edit*/}
                        {/*            </button>*/}
                        {/*            &nbsp;*/}
                        {/*            <button*/}
                        {/*              className="btn btn-sm btn-danger"*/}
                        {/*              onClick={() => handleRemove(dataList.id)}*/}
                        {/*              disabled={loading}*/}
                        {/*            >*/}
                        {/*              {loading ? (*/}
                        {/*                <i className="fa fa-spinner fa-spin"></i>*/}
                        {/*              ) : (*/}
                        {/*                "Remove"*/}
                        {/*              )}*/}
                        {/*            </button>*/}
                        {/*          </>*/}
                        {/*        )}*/}
                        {/*      </td>*/}
                        {/*    </tr>*/}
                        {/*  ))*/}
                        {/*) : (*/}
                        {/*  <tr>*/}
                        {/*    <td colSpan={5} className="text-center">*/}
                        {/*      No matching records found*/}
                        {/*    </td>*/}
                        {/*  </tr>*/}
                        {/*)}*/}
                      </tbody>
                    </table>
                  </div>
                  {/*{holidayList?.length > 0 && (*/}
                  {/*  <div className="row mt-2 justify-content-between">*/}
                  {/*    <div className="col-md-auto me-auto">*/}
                  {/*      <div className="dt-info">*/}
                  {/*        Showing {from} to {to} of {totalData} entries*/}
                  {/*      </div>*/}
                  {/*    </div>*/}
                  {/*    <div className="col-md-auto ms-auto">*/}
                  {/*      <div className="dt-paging paging_full_numbers">*/}
                  {/*        <ul className="pagination">*/}
                  {/*          <li*/}
                  {/*            className={`dt-paging-button page-item ${*/}
                  {/*              page === 1 ? "disabled" : ""*/}
                  {/*            }`}*/}
                  {/*            onClick={() => page !== 1 && set_page(1)}*/}
                  {/*          >*/}
                  {/*            <a*/}
                  {/*              className="page-link first"*/}
                  {/*              aria-controls="tableGroup"*/}
                  {/*              aria-disabled={page === 1}*/}
                  {/*              aria-label="First"*/}
                  {/*              data-dt-idx="first"*/}
                  {/*            >*/}
                  {/*              <span aria-hidden="true">&laquo;</span>*/}
                  {/*            </a>*/}
                  {/*          </li>*/}

                  {/*          /!* Previous Page *!/*/}
                  {/*          <li*/}
                  {/*            className={`dt-paging-button page-item prev ${*/}
                  {/*              page === 1 ? "disabled" : ""*/}
                  {/*            }`}*/}
                  {/*            onClick={() => page !== 1 && set_page(page - 1)}*/}
                  {/*          >*/}
                  {/*            <a*/}
                  {/*              className="page-link previous"*/}
                  {/*              aria-controls="tableGroup"*/}
                  {/*              aria-disabled={page === 1}*/}
                  {/*              aria-label="Previous"*/}
                  {/*              data-dt-idx="previous"*/}
                  {/*            >*/}
                  {/*              <span aria-hidden="true">&lt;</span>*/}
                  {/*            </a>*/}
                  {/*          </li>*/}

                  {/*          /!* Page Numbers *!/*/}
                  {/*          {pagination.map((p: number) => (*/}
                  {/*            <li*/}
                  {/*              key={p}*/}
                  {/*              className={`dt-paging-button page-item ${*/}
                  {/*                page === p ? "active" : ""*/}
                  {/*              }`}*/}
                  {/*              onClick={() => set_page(p)}*/}
                  {/*            >*/}
                  {/*              <a*/}
                  {/*                className="page-link"*/}
                  {/*                aria-controls="tableGroup"*/}
                  {/*                aria-current="page"*/}
                  {/*                data-dt-idx="0"*/}
                  {/*              >*/}
                  {/*                {p}*/}
                  {/*              </a>*/}
                  {/*            </li>*/}
                  {/*          ))}*/}

                  {/*          /!* Next Page *!/*/}
                  {/*          <li*/}
                  {/*            className={`dt-paging-button page-item next ${*/}
                  {/*              page === lastPage ? "disabled" : ""*/}
                  {/*            }`}*/}
                  {/*            onClick={() =>*/}
                  {/*              page !== lastPage && set_page(page + 1)*/}
                  {/*            }*/}
                  {/*          >*/}
                  {/*            <a*/}
                  {/*              className="page-link next"*/}
                  {/*              aria-controls="tableGroup"*/}
                  {/*              aria-label="Next"*/}
                  {/*              data-dt-idx="next"*/}
                  {/*            >*/}
                  {/*              <span aria-hidden="true">&gt;</span>*/}
                  {/*            </a>*/}
                  {/*          </li>*/}

                  {/*          /!* Last Page *!/*/}
                  {/*          <li*/}
                  {/*            className={`dt-paging-button page-item ${*/}
                  {/*              page === lastPage ? "disabled" : ""*/}
                  {/*            }`}*/}
                  {/*            onClick={() =>*/}
                  {/*              page !== lastPage && set_page(lastPage)*/}
                  {/*            }*/}
                  {/*          >*/}
                  {/*            <a*/}
                  {/*              className="page-link last"*/}
                  {/*              aria-controls="tableGroup"*/}
                  {/*              aria-disabled={page === lastPage}*/}
                  {/*              aria-label="Last"*/}
                  {/*              data-dt-idx="last"*/}
                  {/*            >*/}
                  {/*              <span aria-hidden="true">&raquo;</span>*/}
                  {/*            </a>*/}
                  {/*          </li>*/}
                  {/*        </ul>*/}
                  {/*      </div>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*)}*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebLayout>
  );
}

export default AssignStaffDuty;
