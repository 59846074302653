import EmployeeType from "../type/employeeType";

export interface ReducerAction {
  type: string;
  payload?: any;
}

const INITIAL_STATE = {
  employeeList: [],
  employeeRecordList: [],
  currentPage: 0,
  lastPage: 0,
  totalData: 0,
  from: 0,
  to: 0,
  totalPendingKYC: 0,
  totalApprovedKYC: 0,
  totalSendForApprovalKYC: 0,
  totalRejectedKYC: 0,
};

const EmployeeReducers = (state = INITIAL_STATE, action: ReducerAction) => {
  switch (action.type) {
    case EmployeeType.GET_EMPLOYEE_LIST: {
      return {
        ...state,
        employeeList: action.payload.data,
        currentPage: action.payload.currentPage,
        lastPage: action.payload.lastPage,
        totalData: action.payload.totalData,
        from: action.payload.from,
        to: action.payload.to,
        totalPendingKYC: action.payload.totalPendingKYC,
        totalApprovedKYC: action.payload.totalApprovedKYC,
        totalSendForApprovalKYC: action.payload.totalSendForApprovalKYC,
        totalRejectedKYC: action.payload.totalRejectedKYC,
      };
    }

    case EmployeeType.EMPLOYEE_RECORD_LIST: {
      return {
        employeeRecordList: action.payload,
      };
    }

    default:
      return state;
  }
};

export default EmployeeReducers;
